import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Account from './features/Account';
import Home from './features/Home';
import Ladder from './features/Ladder';
import Login from './features/Login';
import Nav from './components/Nav';
import Register from './features/Register';
import util from './util';
import './App.css';

function App() {

  const [app, setApp] = useState(false);
  const [account, setAccount] = useState({});

  useEffect(() => {
    util.initAccount(setAccount);
  }, []);

  useEffect(() => {
    if (app) {
      window.parent?.postMessage({ key: 'jwt', value: util.getJWT() }, '*');
    }
  }, [account, app])

  return (
    <div className="app">
        <BrowserRouter>
          <Nav app={app} setApp={setApp} account={account} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ladder">
              <Route path=":payload" element={<Ladder account={account} app={app} />} />
              <Route path="" element={<Ladder account={account} app={app} />} />
            </Route>
            <Route path="/account" element={<Account account={account} setAccount={setAccount} app={app} />} />
            <Route path="/login" element={<Login account={account} setAccount={setAccount} app={app} />} />
            <Route path="/register" element={<Register account={account} setAccount={setAccount} />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
