import axios from 'axios';
import util from './util';

const ORIGIN = util.getOrigin();
const API_URL = util.getApiUrl();
const api = {
  error: function(err) {
    return { data: { err: `API error: ${err.message}` } };
  },
  registerAccount: async (name, email, pass) => {
    try {
      return await axios.post(API_URL + 'register-account', { name, email, pass, origin: ORIGIN });
    }
    catch (e) {
       return api.error(e);
    }
  },
  confirmAccount: async (code) => {
    try {
      return await axios.post(API_URL + 'confirm-account', { code });
    }
    catch (e) {
       return api.error(e);
    }
  },
  updateAccount: async (account, reset) => {
    try {
      return await axios.post(API_URL + 'update-account', { account, reset, jwt: util.getJWT() });
    }
    catch (e) {
       return api.error(e);
    }
  },
  refreshApiKey: async (account) => {
    try {
      return await axios.post(API_URL + 'refresh-api-key', { account, jwt: util.getJWT() });
    }
    catch (e) {
       return api.error(e);
    }
  },
  login: async (name, pass) => {
    try {
      return await axios.post(API_URL + 'login', { name, pass });
    }
    catch (e) {
       return api.error(e);
    }
  },
  logout: async () => {
    try {
      return await axios.get(API_URL + 'logout');
    }
    catch (e) {
       return api.error(e);
    }
  },
  resetPassword: async (name) => {
    try {
      return await axios.post(API_URL + 'reset-password', { name, origin: ORIGIN });
    }
    catch (e) {
       return api.error(e);
    }
  },
  getCurrentLadders: async () => {
    try {
      return await axios.post(API_URL + 'current-ladders');
    }
    catch (e) {
       return api.error(e);
    }
  },
  getLadders: async (filters, email) => {
    try {
      return await axios.post(API_URL + 'ladders', { filters, email, jwt: util.getJWT() });
    }
    catch (e) {
       return api.error(e);
    }
  },
  getLadderHeroes: async (ladder, options) => {
    try {
      return await axios.post(API_URL + 'ladder-heroes', { ladder, ...options });
    }
    catch (e) {
       return api.error(e);
    }
  },
  getHero: async (id) => {
    try {
      return await axios.post(API_URL + 'hero', { id });
    }
    catch (e) {
       return api.error(e);
    }
  },
  getImage: async (src, callback) => {
    try {
      callback((await axios.post(API_URL + 'image', { src })).data);
    }
    catch (e) {
       return api.error(e);
    }
  },
  upsertPrivateLadder: async (ladder) => {
    try {
      return await axios.post(API_URL + 'upsert-private-ladder', { ladder, jwt: util.getJWT() });
    }
    catch (e) {
       return api.error(e);
    }
  },
  getInfo: async () => {
    try {
      return await axios.get(API_URL + 'info');
    }
    catch (e) {
       return api.error(e);
    }
  }
};

export default api;
