import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api';
import util from '../util';
import './Register.css';

export default function Register(props) {

  // Check URL params-- confirmation code or login id (name/email)
  const { search } = useLocation();
  const [code, setCode] = useState('init');
  const [manualCode, setManualCode] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(search);
    setCode(params.get('code') || '');
    const id = params.get('id');
    if (id) {
      if (/.+@.+\..+/.test(id)) {
        setEmail(id);
      }
      else {
        setName(id);
      }
    }
  }, [search]);

  // Verify confirmation code
  const [status, setStatus] = useState('');
  useEffect(() => {
    (async () => {
      if (code && code !== 'init') {
        setStatus('verifying');
        const res = await api.confirmAccount(code.trim());
        if (res.data.err) {
          return setErr(res.data.err);
        }
        util.setJWT(res.data);
        util.initAccount(props.setAccount)
        setStatus('verified');
      }
    })();
  }, [code, props.setAccount]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [pass2, setPass2] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [err, setErr] = useState('');

  // Clear error when form values change
  useEffect(() => {
    setErr('');
  }, [name, email, pass, pass2]);

  // Handle 'register' event-- email confirmation code
  async function register() {
    if (spinner) {
      return;
    }
    if (!name) {
      return setErr('Username required');
    }
    if (!email) {
      return setErr('Email required');
    }
    if (!/^\s*\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+\s*$/.test(email)) {
      return setErr('Invalid email');
    }
    if (!pass) {
      return setErr('Password required');
    }
    if (pass !== pass2) {
      return setErr('Passwords do not match');
    }
    setSpinner(true);
    const res = await api.registerAccount(name.trim(), email.trim(), pass);
    setSpinner(false);
    if (res.data.err) {
      return setErr(res.data.err);
    }
    setStatus('pending');
  }

  // Handle 'confirm' event-- verify confirmation code
  function confirm() {
    setCode(manualCode);
  }

  // Check for 'enter' on key down-- submit registration or confirmation code
  function keyDown(e, confirmation) {
    if (e?.keyCode === 13) {
      if (confirmation) {
        return confirm();
      }
      register();
    }
  }

  return (
    <div className="register">
      <div>
        {status && <div className="register__status">
          <div>
            {status === 'pending' && <span className="text-info">A confirmation email has been sent to {email}</span>}
            {status === 'verifying' && <span className="text-info">Verifying registration...</span>}
            {status === 'verified' && <span className="text-success">Registration verified, login successful</span>}
            {status === 'invalid' && <span className="text-danger">Invalid registration code</span>}
            {(status === 'pending' || status === 'invalid') && <Form.Group className="register__form register__confirm clearfix">
              <Form.Control onKeyDown={(e) => keyDown(e, true)} value={manualCode} onInput={(e) => setManualCode(e.target.value)}  placeholder="Confirmation Code" />
              <button className="register__submit text-success" onClick={confirm}>Confirm</button>
            </Form.Group>}
          </div>
          {err && <span className="text-danger">{err}</span>}
        </div>}
        {code === '' && !status && <Form.Group className="register__form clearfix">
          <Form.Control onKeyDown={keyDown} value={name} onInput={(e) => setName(e.target.value)}  placeholder="Username" />
          <Form.Control onKeyDown={keyDown} value={email} onInput={(e) => setEmail(e.target.value)}  placeholder="Email address" />
          <Form.Control onKeyDown={keyDown} value={pass} onInput={(e) => setPass(e.target.value)}  placeholder="Password" type="password" />
          <Form.Control onKeyDown={keyDown} value={pass2} onInput={(e) => setPass2(e.target.value)}  placeholder="Confirm Password" type="password" />
          <button className="register__submit text-success" onClick={register}>Register</button>
          {spinner && <Spinner className="register__spinner" animation="border" size="sm" />}
          {err && <span className="register__error text-danger">{err}</span>}
        </Form.Group>}
      </div>
    </div>
  );
}
