import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as PersonIcon } from '../assets/person.svg';
import util from '../util';
import './Nav.css';

export default function Nav(props) {

  const account = props.account;
  const { search } = useLocation();

  // Hide navigation bar when browsing through app
  useEffect(() => {
    const res = new URLSearchParams(search).get('app');
    props.setApp(Boolean(res));
  }, [search, props]);

  if (!account.initialized || props.app) {
    return <div />;
  }
  return (
    <div className="nav">
      <a className="nav__logo" href={util.getOrigin()} rel="noreferrer">D2R.APP</a>
      <ul className="nav__items">
        <li className="nav__item"><NavLink to="/">Home</NavLink></li>
        <li className="nav__item"><a href="/forum" target="_blank" rel="noreferrer">Forum</a></li>
        <li className="nav__item"><NavLink to="/ladder">Ladder</NavLink></li>
        <li className="nav__item">
          <NavLink to={account.name ? '/account' : '/login'}>
            <PersonIcon className="nav__person-icon" />
            {account.name || 'Login'}
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
