import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import DatePicker from 'react-datepicker';
import { decode } from 'url-safe-base64';
import Form from 'react-bootstrap/Form';
import { Hero } from 'd2s-ui-react';
import ReactTooltip from 'react-tooltip';
import Spinner from 'react-bootstrap/Spinner'
import api from '../api';
import LadderList from '../components/LadderList';
import util from '../util';
import { ReactComponent as CopyIcon } from '../assets/clipboard.svg';
import { ReactComponent as EditLadderIcon } from '../assets/pencil-square.svg';
import { ReactComponent as OpenLadderIcon } from '../assets/box-arrow-left.svg';
import scrollHandleImgSrc from '../assets/d2rApp96.png';
import 'react-datepicker/dist/react-datepicker.css';
import './Ladder.css';

export default function Ladder(props) {

  const account = props.account;
  const d2sApiUrl = util.getApiUrl() + 'd2s/img/';
  const [nextLadderId, setNextLadderId] = useState('');
  const [filters, setFilters] = useState({ expansion: true, hardcore: true, private: false, mod: false });
  const [searchSpinner, setSearchSpinner] = useState(false);
  const [ladders, setLadders] = useState([]);
  const [ladder, setLadder] = useState({});
  const [searchMod, setSearchMod] = useState('');
  const [searchHero, setSearchHero] = useState('');
  const [noMatchingLadders, setNoMatchingLadders] = useState(false);

  const [content, setContent] = useState('');
  const [hero, setHero] = useState({});
  const heroCache = useRef({});
  const [contentSpinner, setContentSpinner] = useState(false);
  const [errLeft, setErrLeft] = useState('');
  const [errRight, setErrRight] = useState('');

  const [currentLadders, setCurrentLadders] = useState([]);
  const [currentLadderStart, setCurrentLadderStart] = useState('');
  const [copiedLadderId, setCopiedLadderId] = useState(0);

  let { payload } = useParams();
  const [contract, setContract] = useState({});
  useEffect(() => {
    if (payload) {
      try {
        setContract({ ...JSON.parse(atob(decode(payload))) });
      }
      catch (e) {

      }
    }
  }, [payload]);

  useEffect(() => {
    if (contract.heroId) {
      setHero({ id: contract.heroId });
      setNextLadderId(contract.ladderId);
      setFilters((c) => {
        return { ...c, expansion: contract.expansion, hardcore: contract.hardcore, private: contract.private, mod: contract.mod };
      });
    }
  }, [contract]);

  const showAdds = useCallback(() => {
    return false;//account.name && !account.hideAdds && !props.app;
  }, []);

  useEffect(() => {
    if (showAdds()) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('async', true);
      script.setAttribute('crossorigin', 'anonymous');
      script.setAttribute('src', 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7016188098053319');
      head.appendChild(script);
      return () => {
        head.removeChild(script);
      };
    }
  }, [account, showAdds]);

  useEffect(() => {
    // Get cookies
    if (!filters.initialized) {
      const cookies = new Cookies();
      const cFilters = { ...filters, initialized: true };
      const id = cookies.get('id');
      if (id) {
        setNextLadderId(id);
      }
      const expansion = parseInt(cookies.get('expansion'));
      if (!isNaN(expansion)) {
        cFilters.expansion = !!expansion;
      }
      const hardcore = parseInt(cookies.get('hardcore'));
      if (!isNaN(hardcore)) {
        cFilters.hardcore = !!hardcore;
      }
      const _private = parseInt(cookies.get('private'));
      if (!isNaN(_private)) {
        cFilters.private = !!_private;
      }
      const mod = parseInt(cookies.get('mod'));
      if (!isNaN(mod)) {
        cFilters.mod = !!mod;
      }
      setFilters(cFilters);
    }
    // Get current ladders
    if (!currentLadderStart) {
      (async () => {
        const res = await api.getCurrentLadders(filters);
        setCurrentLadders([...res.data]);
        if (res.data.length) {
          const startDate = new Date(res.data[0].startTime);
          setCurrentLadderStart(' (started ' + startDate.toLocaleString() + ')');
        }
      })();
    }
  }, [filters, currentLadderStart]);

  // Refreshes ladders based on current filters
  const refreshLadders = useCallback(() => {
    if (filters.initialized) {
      setLadders([]);
      // Must be logged in to view private ladders
      if (filters.private && !account.name) {
        return;
      }
      setSearchSpinner(true);
      util.debounce('ladders', 1000, async () => {
        util.timeouts.ladders = null;
        setNoMatchingLadders(false);
        const res = await api.getLadders(filters);
        if (!util.timeouts.ladders) {
          setSearchSpinner(false);
        }
        if (res.data.err) {
          return setErrLeft(res.data.err);
        }
        setLadders([...res.data]);
        if (!res.data.length) {
          setNoMatchingLadders(true);
        }
      });
    }
  }, [filters, account.name]);

  // Watch for search changes-- update cookie and refresh ladders
  useEffect(() => {
    if (filters.initialized) {
      const cookies = new Cookies();
      cookies.set('expansion', filters.expansion ? 1 : 0, { path: '/' });
      refreshLadders();
    }
  }, [filters.expansion, filters.initialized, refreshLadders]);

  useEffect(() => {
    if (filters.initialized) {
      const cookies = new Cookies();
      cookies.set('hardcore', filters.hardcore ? 1 : 0, { path: '/' });
      refreshLadders();
    }
  }, [filters.hardcore, filters.initialized, refreshLadders]);

  useEffect(() => {
    if (filters.initialized) {
      const cookies = new Cookies();
      cookies.set('private', filters.private ? 1 : 0, { path: '/' });
      refreshLadders();
    }
  }, [filters.private, filters.initialized, refreshLadders]);

  useEffect(() => {
    if (filters.initialized) {
      const cookies = new Cookies();
      cookies.set('mod', filters.mod ? 1 : 0, { path: '/' });
      refreshLadders();
    }
  }, [filters.mod, filters.initialized, refreshLadders]);

  function setLadderIdCookie(value) {
    const cookies = new Cookies();
    cookies.set('id', value, { path: '/' });
  }

  // When ladders change, select a new initial ladder
  useEffect(() => {
    let initialLadder = {};
    if (ladders.length) {
      initialLadder = ladders[0];
      for (const _ladder of ladders) {
        if (_ladder.id === nextLadderId) {
          initialLadder = _ladder;
          break;
        }
      }
    }
    setLadder({ ...initialLadder });
  }, [ladders, nextLadderId]);

  // Manages values for search filter
  function onFilterChange(e) {
    setFilters({ ...filters, [e.target.name]: e.target.checked });
  }

  function ladderSelected(e) {
    const ladderId = e.target.value;
    setLadderIdCookie(ladderId);
    setLadder({ ...ladders.filter(_ladder => _ladder.id === ladderId)[0] });
  }

  // Loads ladder items (heroes) (passed to LadderList)
  async function loadLadderItems(options, callback) {
    const res = await api.getLadderHeroes(ladder, options);
    if (res.data.err) {
      return setErrLeft(res.data.err);
    }
    if (res.data.heroes) {
      for (const hero of res.data.heroes) {
        hero.expansion = ladder.expansion;
        hero.hardcore = ladder.hardcore;
      }
    }
    callback(res.data);
  }

  // Clear hero cache on ladder change
  useEffect(() => {
    heroCache.current = {};
  }, [ladder])

  // Get hero data from cache, or server, when hero changes
  useEffect(() => {
    (async() => {
      if (hero.id) {
        setContent('hero');
        if (!hero.data) {
          if (heroCache.current[hero.rank]) {
            return setHero({ ...heroCache.current[hero.rank] });
          }
          setContentSpinner(true);
          let res = await api.getHero(hero.id);
          res = res?.data;
          setContentSpinner(false);
          if (!res) {
            return setErrRight('Hero not found');
          }
          setErrRight(res.err || '');
          if (!res.err) {
            heroCache.current[hero.rank] = res;
            setHero({ ...res });
          }
        }
      }
    })();
  }, [hero]);

  // Gets a ladder's label (ladder select option)
  function getLadderLabel(ladder) {
    if (ladder.startTime && !ladder.private) {
      const startDate = new Date(ladder.startTime);
      let year = startDate.getUTCFullYear();
      const month = new Intl.DateTimeFormat('en-US', { month: 'long', timeZone: 'UTC' }).format(startDate);
      return `${year} ${month}`;
    }
    return ladder.name;
  }

  // Copy button for ladder ids
  const CopyLadderId = (props) => {
    const { id, name } = props.ladder;
    const CopyButton = () => {
      const onClick = () => {
        navigator.clipboard.writeText(id);
        setCopiedLadderId(id);
      };
      const mouseEnter = () => { ReactTooltip.hide() };
      const mouseLeave = () => { ReactTooltip.hide() };
      return (
        <button className="ladder__copy-button" onClick={onClick} data-tip={`Copy ${name} Ladder ID ( ${id} )`} data-for={`copy${id}`} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <CopyIcon className="ladder__copy-icon" />
        </button>
      );
    };
    return (
      <span>
        {props.placement === 'left' && <CopyButton />}
        <span className="ladder__ladder-id">{id}</span>
        {props.placement !== 'left' && <CopyButton />}
        {copiedLadderId === id && <span className="ladder__ladder-id-copied">copied</span>}
        <ReactTooltip id={`copy${id}`} />
      </span>
    );
  };

  // Clear "copied" message automatically
  useEffect(() => {
      const copyTimeout = setTimeout(() => setCopiedLadderId(0), 3000);
      return () => clearTimeout(copyTimeout);
  }, [copiedLadderId]);

  //
  // Private ladder
  //
  const getDefaultPrivateLader = () => { return { expansion: true, hardcore: false, mod: false, name: '', description: '', startTime: new Date().getTime() }};
  const [privateLadder, setPrivateLadder] = useState(getDefaultPrivateLader());
  const [privateLadderSpinner, setPrivateLadderSpinner] = useState(false);
  const [privateLadderSuccess, setPrivateLadderSuccess] = useState(false);
  const [privateLadderErr, setPrivateLadderErr] = useState('');
  const [privateLadderSV, setPrivateLadderSV] = useState({});
  const [privateLadders, setPrivateLadders] = useState([]);

  function getLadderType(ladder) {
    return (ladder.mod ? 'Modded ' : '') + (ladder.expansion ? 'LoD' : 'Classic') + (ladder.hardcore ? ' hardcore' : ' softcore');
  }

  function openPrivateLadder(ladder) {
    setLadderIdCookie(ladder.id);
    setNextLadderId(ladder.id);
    setFilters({ ...filters, expansion: ladder.expansion, hardcore: ladder.hardcore, mod: ladder.mod, private: true });
  }

  function editPrivateLadder(ladder) {
    setPrivateLadderSV({ ...ladder });
    setPrivateLadder({ ...ladder });
    setContent('create');
  }

  function privateLadderChange(key, value) {
    setPrivateLadder({ ...privateLadder, [key]: value });
  }

  function privateLadderKeyDown(e) {
    if (e?.keyCode === 13) {
      savePrivateLadder();
    }
  }

  function getPrivateLadderSaveLabel() {
    if (privateLadderSpinner) {
      if (privateLadder.id) {
        return 'Saving...';
      }
      return 'Creating...';
    }
    if (privateLadderSuccess) {
      return 'Success!'
    }
    if (privateLadder.id) {
      return 'Save';
    }
    return 'Create';
  }

  async function savePrivateLadder() {
    if (privateLadderSpinner || privateLadderSuccess) {
      return;
    }
    if (!account.name) {
      return setPrivateLadderErr('Login required')
    }
    if (!privateLadder.name) {
      return setPrivateLadderErr('Name required')
    }
    if (!privateLadder.description) {
      return setPrivateLadderErr('Description required')
    }
    if (!privateLadder.startTime) {
      return setPrivateLadderErr('Date required')
    }
    setPrivateLadderSpinner(true);
    util.debounce('private-ladder', 1000, async () => {
      const res = await api.upsertPrivateLadder({ ...privateLadder });
      setPrivateLadderSpinner(false);
      if (res.data.err) {
        return setPrivateLadderErr(res.data.err);
      }
      setPrivateLadderErr('');
      const newLadder = { ...res.data, startDate: new Date(res.data.startTime) };
      setPrivateLadder(newLadder);
      setPrivateLadderSV(newLadder);
      setPrivateLadderSuccess(true);
    });
  }

  // Check for dirty private ladder
  const [privateLadderDirty, setPrivateLadderDirty] = useState(false);
  useEffect(() => {
    let needs = false;
    if (privateLadderSV.id) {
      for (const key of Object.keys(privateLadder)) {
        if (privateLadder[key] !== privateLadderSV[key]) {
          needs = true;
          break;
        }
      }
      setPrivateLadderDirty(needs);
    }
    else {
      setPrivateLadderDirty(true);
    }
  }, [privateLadder, privateLadderSV]);

  // Clear success confirmation automatically
  useEffect(() => {
      const timeout = setTimeout(() => setPrivateLadderSuccess(false), 3000);
      return () => clearTimeout(timeout);
  }, [privateLadderSuccess]);

  // On change of right-side content
  useEffect(() => {
    (async () => {
      if (content !== 'hero' && hero._id) {
        setHero({});
      }
      if (content !== 'create' && privateLadder.id) {
        setPrivateLadder(getDefaultPrivateLader());
      }
      if (content === 'manage' && account.name) {
        setPrivateLadders([]);
        setContentSpinner(true);
        const res = await api.getLadders({ private: true }, account.email);
        setContentSpinner(false);
        if (res.data.err) {
          return setErrRight(res.data.err);
        }
        setPrivateLadders([...res.data]);
      }
    })();
  }, [content, hero._id, privateLadder.id, account.name, account.email]);

  return (
    <div className="ladder">
      <div className="ladder__left">
        <div className="ladder__search">
          <Form.Group controlId="expansion" className="ladder__search__group">
            <Form.Label className="ladder__search__label">Expansion</Form.Label>
            <Form.Check inline name="expansion" checked={filters.expansion} value={filters.expansion} onChange={onFilterChange} />
          </Form.Group>
          <Form.Group controlId="hardcore" className="ladder__search__group">
            <Form.Label className="ladder__search__label">Hardcore</Form.Label>
            <Form.Check inline name="hardcore" checked={filters.hardcore} value={filters.hardcore} onChange={onFilterChange} />
          </Form.Group>
          <Form.Group controlId="mod" className="ladder__search__group">
            <Form.Label className="ladder__search__label">Mod</Form.Label>
            <Form.Check inline name="mod" checked={filters.mod} value={filters.mod} onChange={onFilterChange} />
          </Form.Group>
          <Form.Group controlId="private" className="ladder__search__group">
            <Form.Label className="ladder__search__label">Private</Form.Label>
            <Form.Check inline name="private" checked={filters.private} value={filters.private} onChange={onFilterChange} />
          </Form.Group>
        </div>
        <div className="ladder__search">
          <Form.Select bsPrefix="custom-select" value={ladder.id} onChange={(e) => ladderSelected(e)}>
            {ladders.map(_ladder => <option key={_ladder.id} value={_ladder.id}>{getLadderLabel(_ladder)}</option>)}
          </Form.Select>
          {ladder.mod && <Form.Control className="ladder__search__mod" value={searchMod} onInput={(e) => setSearchMod(e.target.value)} placeholder="Mod name" />}
          {!!ladder.heroCount && <Form.Control className="ladder__search__hero" value={searchHero} onInput={(e) => setSearchHero(e.target.value)} placeholder="Hero name" />}
        </div>
        {searchSpinner && <Spinner className="ladder__search__spinner" animation="border" size="sm" />}
        {!searchSpinner && <div>
          {errLeft && <div className="text-danger">{errLeft}</div>}
          {!errLeft && ladder.id && <div>
            {ladder.id !== 'all' && <div className="ladder__info clearfix">
              {ladder.name && <div>
                <div className="ladder__info__label">Name</div>
                <div className="ladder__info__value">{ladder.name}</div>
              </div>}
              {ladder.description && <div>
                <div className="ladder__info__label">Description</div>
                <div className="ladder__info__value">{ladder.description}</div>
              </div>}
              {!!ladder.startTime && <div>
                <div className="ladder__info__label">Start date</div>
                <div className="ladder__info__value">{new Date(ladder.startTime).toLocaleString()}</div>
              </div>}
              {/^[a-z]/.test(ladder.id) && <div>
                <div className="ladder__info__label">Ladder ID</div>
                <div className="ladder__info__value">
                  <CopyLadderId ladder={ladder} />
                </div>
              </div>}
            </div>}
            <LadderList
              key={ladder.id + searchMod + searchHero}
              id={ladder.id}
              loadItems={loadLadderItems}
              itemClick={(item) => setHero({ ...item })}
              filters={{ mod: searchMod, name: searchHero }}
              scrollHandleImgSrc={scrollHandleImgSrc}
              marginBottom={25 + (showAdds() ? 125 : 0)}
              ladder={ladder}
              start={contract.rank ? contract.rank - 1 : 0}
              selectedId={hero._id}
            />
          </div>}
          {noMatchingLadders && <div>No matching ladders</div>}
          {filters.private && !account.name && <span className="text-info">You must login to view private ladders</span>}
        </div>}
        {showAdds() && <div className="ladder__left__add">
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-7016188098053319"
            data-ad-slot="6968097017"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>}
      </div>
      <div className={'ladder__right' + (props.app ? ' ladder__right--app' : '')}>
        {contentSpinner && <Spinner animation="border" size="sm" />}
        {errRight && <div className="text-danger">{errRight}</div>}
        {!errRight && <div>
          {content === 'hero' && hero.name && !contentSpinner && <div>
            <button className="ladder__close" onClick={() => setContent('')}>X</button>
            <Hero hero={hero.data} api={d2sApiUrl} />
          </div>}
          {content === 'manage' && <div>
            <button className="ladder__close" onClick={() => setContent('')}>X</button>
            {account.name && <div>
              Private ladders <span className="ladder__private__count">({privateLadders.length}/{(account.donationCount + 1) * 24})</span>
              <div className="form-control ladder__private__list">
                <button className="ladder__private__create-button" onClick={() => setContent('create')}>Create</button>
                <table>
                  <tbody>
                    {!!privateLadders.length && <tr className="ladder__private__th">
                      <td></td>
                      <td>Name</td>
                      <td>Type</td>
                      <td>Start date</td>
                      <td>Description</td>
                    </tr>}
                    {privateLadders.map(ladder => <tr key={ladder.id}>
                      <td className="ladder__private__td">
                        <button className="ladder__private__btn" data-tip={`Open ${ladder.name}`} data-for={`action${ladder.id}`} onClick={() => openPrivateLadder(ladder)}><OpenLadderIcon /></button>
                        {ladder.owner && <button className="ladder__private__btn" data-tip={`Edit ${ladder.name}`} data-for={`action${ladder.id}`} onClick={() => editPrivateLadder(ladder)}><EditLadderIcon /></button>}
                        <ReactTooltip id={`action${ladder.id}`} />
                      </td>
                      <td className="ladder__private__td">{ladder.name}</td>
                      <td className="ladder__private__td">{getLadderType(ladder)}</td>
                      <td className="ladder__private__td">{new Date(ladder.startTime).toLocaleDateString()}</td>
                      <td className="ladder__private__td">{ladder.description}</td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>}
            {!account.name && <span className="text-info">You must login to participate in private ladders</span>}
          </div>}
          {content === 'create' && !contentSpinner && <div>
            <button className="ladder__close" onClick={() => setContent('manage')}>X</button>
            <div className="ladder__private">
              <div className="ladder__private__checkboxes">
                <Form.Group controlId="private-expansion">
                  <Form.Label className="ladder__private__checkbox-label">Expansion</Form.Label>
                  <Form.Check inline checked={privateLadder.expansion} value={privateLadder.expansion} onChange={(e) => privateLadderChange('expansion', e.target.checked)} disabled={privateLadder.id} />
                </Form.Group>
                <Form.Group controlId="private-hardcore">
                  <Form.Label className="ladder__private__checkbox-label">Hardcore</Form.Label>
                  <Form.Check inline checked={privateLadder.hardcore} value={privateLadder.hardcore} onChange={(e) => privateLadderChange('hardcore', e.target.checked)} disabled={privateLadder.id} />
                </Form.Group>
                <Form.Group controlId="private-mod">
                  <Form.Label className="ladder__private__checkbox-label">Mod</Form.Label>
                  <Form.Check inline checked={privateLadder.mod} value={privateLadder.mod} onChange={(e) => privateLadderChange('mod', e.target.checked)} disabled={privateLadder.id} />
                </Form.Group>
              </div>
              <Form.Group controlId="private-name" className="clearfix">
                <Form.Label className="ladder__private__label">Name</Form.Label>
                <Form.Control className="ladder__private__control" value={privateLadder.name} onInput={(e) => privateLadderChange('name', e.target.value)} onKeyDown={privateLadderKeyDown} />
              </Form.Group>
              <Form.Group controlId="private-description" className="clearfix">
                <Form.Label className="ladder__private__label">Description</Form.Label>
                <Form.Control className="ladder__private__control" value={privateLadder.description} onInput={(e) => privateLadderChange('description', e.target.value)} onKeyDown={privateLadderKeyDown} />
              </Form.Group>
              <Form.Group controlId="private-start" className="clearfix">
                <Form.Label className="ladder__private__label">Start date</Form.Label>
                <DatePicker
                  className="form-control ladder__private__control"
                  selected={new Date(privateLadder.startTime)}
                  onChange={(date) => privateLadderChange('startTime', date.getTime())}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Form.Group>
              {!!privateLadder.id && <Form.Group className="ladder__private__id-group clearfix">
                <Form.Label className="ladder__private__label">Ladder ID</Form.Label>
                <div className="form-control ladder__private__control"><CopyLadderId ladder={privateLadder} /></div>
              </Form.Group>}
              {(privateLadderDirty || privateLadderSuccess) && <button className="ladder__private__submit text-success" onClick={savePrivateLadder}>{getPrivateLadderSaveLabel()}</button>}
              {privateLadderSpinner && <Spinner className="ladder__private__spinner" animation="border" size="sm" />}
              {privateLadderErr && <div className="text-danger ladder__private__error">{privateLadderErr}</div>}
            </div>
          </div>}
          {!content && !contentSpinner && <div>
            <div className="ladder__header">Ladders...</div>
            <ul>
              <li>Run monthly, starting 01:00 UTC on the first day of each month</li>
              <li>Never end</li>
              <li>Require a newly-created hero (with the current month's ladder tag)
                <ul>
                  <li>HeroName-LadderTag, e.g. Aaedin-qwert</li>
                </ul>
              </li>
              <li>Are a way to view progress relative other heroes created during the same month</li>
              <li>Can be viewed alongside other same-type ladders, i.e. "All LoD softcore"</li>
            </ul>
            <div className="ladder__header">Current public ladders<span className="ladder__current-start-date">{currentLadderStart}</span>:</div>
            {currentLadders.length > 0 && <ul className="ladder__current-ladders">
              {currentLadders.map(ladder => <li key={ladder.id}>
                <span className="ladder__current-ladders__name">{ladder.name}</span>
                <CopyLadderId ladder={ladder} placement="left" />
              </li>)}
            </ul>}
            {!currentLadders.length && <Spinner animation="border" size="sm" />}
            <div className="ladder__header">
              <button className="ladder__create" onClick={() => setContent('manage')}>Private ladders</button>
            </div>
            {showAdds() && <div className="ladder__right__add">
              <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7016188098053319"
                data-ad-slot="6270663837"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </div>}
          </div>}
        </div>}
      </div>
    </div>
  );
}
