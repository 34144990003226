import { PureComponent, useCallback, useEffect, useRef, useState } from 'react';
import util from '../util';
import { ReactComponent as TombstoneIcon } from '../assets/tombstone.svg';
import './LadderList.css';

export default function LadderList(props) {

  const listRef = useRef(null);
  const listHeaderRef = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const [listStyle, setListStyle] = useState({});
  const itemHeight = 30;
  const [pageSize, setPageSize] = useState(0);

  // Must use reference to access up-to-date start index within externally provided callback
  let startRef = useRef(0);
  const setCurrentStart = (value) => startRef.current = value;
  const getCurrentStart = () => startRef.current;
  const [currentItems, setCurrentItems] = useState([]);

  const [items] = useState([]);
  const [itemCount, setItemCount] = useState(-1);

  const handleRef = useRef(null);
  const [handleY, setHandleY] = useState(0);

  //
  // Callbacks
  //
  // Load items (preload, then debounce)
  const loadItemsCb = useCallback((start = 0) => {
      if (!pageSize) {
        throw new Error('Invalid page size (zero)');
      }
      setCurrentStart(start);
      const getItemProps = (index) => {
        return { index: index, style: { height: itemHeight + 'px', lineHeight: itemHeight + 'px' } };
      };
      // Preload
      let needsItems = false;
      const _currentItems = [];
      for (let i = start; i < start + pageSize; i ++) {
        if (!items[i]?.name) {
          items[i] = { ...getItemProps(i) };
          needsItems = true;
        }
        _currentItems.push(items[i]);
      }
      setCurrentItems(_currentItems);
      // Debounce
      if (needsItems) {
        util.debounce('ladder', 500, function() {
          props.loadItems({ ...props.filters, start, limit: pageSize, itemCount }, (data) => {
            if (data.start === getCurrentStart()) {
              const _currentItems = [];
              data.heroes.forEach((item, i) => {
                const index = i + getCurrentStart();
                items[index] = { ...item, ...getItemProps(index) };
                _currentItems.push(items[index]);
              });
              setInitialized(true);
              setCurrentItems(_currentItems);
              if (~data.total) {
                setItemCount(data.total);
              }
            }
          });
        });
      }
  }, [items, pageSize, props, itemCount]);

  // Bind mouse wheel (scroll tick = half page)
  const bindMouseWheelCb = useCallback(() => {
    listRef.current.onwheel = (e) => {
      e.preventDefault();
      if (e.deltaY) {
        let start = getCurrentStart() + Math.ceil(pageSize / 2 * Math.sign(e.deltaY));
        if (start < 0) {
          start = 0;
        }
        else if (start + 1 + pageSize > itemCount){
          start = itemCount - pageSize;
        }
        // Update scroll handle position
        if (start) {
          const listRect = listRef.current.getBoundingClientRect();
          const max = listRect.height - handleRef.current.getBoundingClientRect().height;
          let y = (start + pageSize / 2) / itemCount * listRect.height;
          if (y > max) {
            y = max;
          }
          setHandleY(y);
        }
        else {
          setHandleY(0);
        }
        loadItemsCb(start);
      }
    };
  }, [itemCount, loadItemsCb, pageSize]);

  // Bind scroll handle (find page based on scroll percent)
  const bindScrollHandleCb = useCallback(() => {
    const el = handleRef.current;
    if (el) {
      el.onpointerdown = (e) => {
        el.setPointerCapture(e.pointerId);
        el.onpointermove = (e) => {
          let y = e.clientY;
          const listRect = listRef.current.getBoundingClientRect();
          y -= listRect.y;
          const min = 0;
          const max = listRect.height - handleRef.current.getBoundingClientRect().height;
          if (y < min) {
            y = min;
          }
          else if (y > max) {
            y = max;
          }
          setHandleY(y);
          let start = Math.floor(itemCount * y / max);
          if (start + 1 + pageSize > itemCount){
            start = itemCount - pageSize - 1;
            if (start < 0) {
              start = 0;
            }
          }
          loadItemsCb(start);
        };
        el.onpointerup = (e) => {
          el.releasePointerCapture(e.pointerId);
          el.onpointermove = null;
          el.onpointerup = null;
        };
      };
    }
  }, [itemCount, loadItemsCb, pageSize]);

  //
  // Update list dimensions (on load and resize)
  //
  const updateListDim = useCallback(() => {
    const el = listRef.current;
    if (el) {
      const height = window.innerHeight - el.offsetTop - props.marginBottom;
      const display = 'flex';
      if (listStyle.height !== height || listStyle.display !== display) {
        setListStyle({ ...listStyle, height, display });
      }
    }
  }, [listStyle, props.marginBottom]);

  //
  // Step 1: Update list dimensions
  //
  useEffect(() => {
    updateListDim();
  }, [updateListDim]);

  // Step 2: Set page size
  useEffect(() => {
    const listHeight = listRef.current.getBoundingClientRect().height;
    const listHeaderHeight = listHeaderRef.current.getBoundingClientRect().height;
    setPageSize(Math.floor((listHeight - listHeaderHeight) / itemHeight));;
  }, [listStyle]);

  // Step 3: Load initial data
  useEffect(() => {
    if (pageSize && !initialized) {
      loadItemsCb(props.start || 0);
    }
  }, [pageSize, loadItemsCb, initialized, props.start]);

  // Step 4: Bind actions
  useEffect(() => {
    if (itemCount > pageSize) {
      bindMouseWheelCb();
      bindScrollHandleCb();
    }
  }, [itemCount, bindMouseWheelCb, bindScrollHandleCb, pageSize]);

  const resizeList = useCallback(() => {
    // Hide list
    setListStyle({ ...listStyle, display: 'none' });
    // Debounce, then recalc/show
    util.debounce('resize', 100, updateListDim);
  }, [listStyle, updateListDim]);

  useEffect(() => {
    window.addEventListener('resize', resizeList);
    return () => { window.removeEventListener('resize', resizeList) };
  }, [resizeList]);

  const HeroName = (props) => {
    const hero = props.hero;
    return(<div className="ladder-list__hero-info">
      <div className={'ladder-list__hero-info__general' + (hero.hardcore && hero.dead ? ' ladder-list__hero-info__general--dead' : '')}>
        {hero.level} {hero.class}
      </div>
      {hero.mod && <div className="ladder-list__hero-info__mod">{hero.mod}</div>}
      <div className="text-info ladder-list__hero-info__name">{hero.name}</div>
    </div>);
  };

  class Item extends PureComponent {
    render() {
      const { index, style, itemClick } = this.props;
      const item = items[index];
      const activeCls = item.id && item.id === props.selectedId ? ' ladder-list__item--active' : '';
      if (item?.name) {
        return (
          <div className={`ladder-list__item${activeCls}`} style={style} onClick={() => itemClick({ ...item })}>
            <div className="ladder-list__item__rank">{item.rank.toLocaleString()}</div>
            <div className="ladder-list__item__name">
              <HeroName hero={item} />
              {!!item.dead && <TombstoneIcon className="ladder-list__item__tombstone-icon" />}
            </div>
          </div>
        );
      }
      return (
        <div className="ladder-list__item" style={style}>
          <div className="ladder-list__item__rank">--</div>
          <div className="ladder-list__item__name">
            <HeroName hero={{ level: '--', mod: props.ladder.mod ? '--' : '', name: 'Loading...' }} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="ladder-list" ref={listRef} style={{ visibility: props.id ? 'visible' : 'hidden', marginBottom: props.marginBottom }}>
      <div className="ladder-list__wrapper" style={listStyle}>
        <div className="ladder-list__content" style={{ height: listStyle.height }}>
          <div className="ladder-list__item ladder-list__header" ref={listHeaderRef}>
            <div className="ladder-list__item__rank">Rank</div>
            <div className="ladder-list__item__name">Hero</div>
          </div>
          {currentItems.map(item => <Item key={props.id + item.index} index={item.index} style={item.style} itemClick={props.itemClick} />)}
        </div>
        {itemCount > pageSize && <div className="ladder-list__scroll" style={{ height: listStyle.height }}>
          <div className="ladder-list__scroll-bar" style={{ height: listStyle.height }}>
            <img className="ladder-list__scroll-handle" ref={handleRef} style={{ top: `${handleY}px` }} src={props.scrollHandleImgSrc} alt="Scroll handle" draggable="false" />
          </div>
        </div>}
      </div>
    </div>
  );
}
