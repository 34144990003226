import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ReactTooltip from 'react-tooltip';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api';
import util from '../util';
import { ReactComponent as ArrowClockwiseIcon } from '../assets/arrow-clockwise.svg';
import './Account.css';

export default function Account(props) {

  const { search } = useLocation();
  const navigate = useNavigate();
  const account = props.account;
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState('');
  const [reset, setReset] = useState('');
  const useJWT = useRef(true);

  useEffect(() => {
    if (false && account.name) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://donorbox.org/install-popup-button.js');
      head.appendChild(script);
      return () => {
        head.removeChild(script);
      };
    }
  }, [account.name]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    setReset(params.get('reset') || '');
  }, [search]);

  // If not logged in, check for reset param before redirecting to login
  // Also check for JWT param (from app)
  useEffect(() => {
    if (account.initialized && !account.name) {
      const params = new URLSearchParams(search);
      const _reset = params.get('reset') || '';
      if (_reset) {
        return setReset(_reset);
      }
      const jwt = useJWT.current ? params.get('jwt') : false;
      if (!(jwt && util.initAccount(props.setAccount, jwt))) {
        util.nav(navigate, '/login', search);
      }
    }
  }, [account.initialized, account.name, navigate, search, props.setAccount]);

  // When 'server' account changes, copy to 'local' account used for form
  const [ _account, _setAccount] = useState({ name: '', email: '', pass: '', pass2: '' });
  useEffect(() => {
    _setAccount(_account => { return { ..._account, ...account } });
  }, [account]);

  // Check for dirty account
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    let needs = false;
    if (account.name || reset) {
      for (const key of Object.keys(_account).filter(key => key !== 'apiKey')) {
        if (/pass/.test(key)) {
          if (_account.pass || _account.pass2) {
            needs = true;
            break;
          }
        }
        else if (!reset && _account[key] !== account[key]) {
          needs = true;
          break;
        }
      }
      setUpdate(needs);
    }
  }, [_account, account, reset]);

  // Handle 'input' event-- set local account info
  function input(e) {
    _setAccount({ ..._account, [e.target.id]: e.target.value });
  }

  // Check for 'enter' on key down-- submit account form
  function keyDown(e) {
    if (e?.keyCode === 13) {
      save();
    }
  }

  // Gets the save button's label
  function getSaveLabel() {
    if (spinner) {
      return 'Saving...';
    }
    if (success) {
      return 'Success!'
    }
    return 'Save';
  }

  // Handle 'save' event-- submit account form, refresh account info
  async function save() {
    if (spinner) {
      return;
    }
    if ((_account.pass || _account.pass2) && _account.pass !== _account.pass2) {
      return setErr('Passwords do not match');
    }
    setSpinner(true);
    util.debounce('update', 1000, async () => {
      const res = await api.updateAccount(_account, reset);
      setSpinner(false);
      if (res.data.err) {
        return setErr(res.data.err);
      }
      // Re-initialize with updated account
      if (!util.initAccount(props.setAccount, res.data)) {
        return util.nav(navigate, '/login');
      }
      setSuccess(true);
      _setAccount({ ..._account, pass: '', pass2: '' });
      // Clear reset url param
      if (reset) {
        return util.nav(navigate, '/account');
      }
    });
  }

  // Clear success confirmation automatically
  useEffect(() => {
      const timeout = setTimeout(() => setSuccess(false), 3000);
      return () => clearTimeout(timeout);
  }, [success]);

  // Handle 'logout' event-- clear JWT, navigate to login
  function logout() {
    useJWT.current = false;
    util.removeJWT();
    if (props.app) {
      window.parent?.postMessage({ key: 'jwt', value: null }, '*');
    }
    else {
      util.initAccount(props.setAccount);
    }
    // Clear http-only cookie
    api.logout();
  }

  // Refresh API key
  function refreshApiKey() {
    _setAccount({ ..._account, apiKey: 'Loading...' });
    util.debounce('update', 1000, async () => {
      const res = await api.refreshApiKey(_account);
      if (res.data.err) {
        return _setAccount({ ..._account, apiKey: res.data.err });
      }
      // Re-initialize with updated account
      if (!util.initAccount(props.setAccount, res.data)) {
        return util.nav(navigate, '/login');
      }
    });
  }

  // Return empty div if account info not available
  if (!(account.name || reset)) {
    return <div />;
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        {!reset && <div className="clearfix">
          <button className="account__logout" onClick={logout}>Logout</button>
        </div>}
        {!reset && <div>
            <Form.Group controlId="name" className="account__form__group">
              <Form.Label className="account__label">Username</Form.Label>
              <Form.Control className="account__control" value={_account.name} readOnly />
            </Form.Group>
            <Form.Group controlId="email" className="account__form__group">
              <Form.Label className="account__label">Email</Form.Label>
              <Form.Control className="account__control" value={_account.email} readOnly />
            </Form.Group>
            {props.app && <Form.Group controlId="name" className="account__form__group">
              <Form.Label className="account__label">API Key</Form.Label>
              <Form.Control className="account__control" value={_account.apiKey || ''} readOnly />
              <button className="account__api__refresh" data-tip="Refresh API Key" data-for="refresh-api-key" onClick={refreshApiKey}>
                <ArrowClockwiseIcon className="account__api__refresh-icon" />
              </button>
              <ReactTooltip id="refresh-api-key" />
            </Form.Group>}
        </div>}
        <Form.Group controlId="pass" className="account__form__group">
          <Form.Label className="account__label">Password</Form.Label>
          <Form.Control className="account__control"
            value={_account.pass} onInput={input} onKeyDown={keyDown} placeholder="New password (optional)" type="password"
          />
        </Form.Group>
        <Form.Group controlId="pass2" className="account__form__group">
          <Form.Label className="account__label" />
          <Form.Control className="account__control"
            value={_account.pass2} onInput={input} onKeyDown={keyDown} placeholder="Confirm new password" type="password"
          />
        </Form.Group>
        {(update || success) && <button className="account__submit text-success" onClick={save}>{getSaveLabel()}</button>}
        {spinner && <Spinner className="account__spinner" animation="border" size="sm" />}
        {err && <div className="text-danger account__error">{err}</div>}
      </div>
      {false && account.name && <div className="account__donations">
        {!account.donationCount && <span>If you're enjoying d2r.app, please consider donating!</span>}
        {!!account.donationCount && <span>Thanks for the donation{account.donationCount > 1 ? 's' : ''}!</span>}
      </div>}
      {false && <div className="account__donate">
        <a className="dbox-donation-button" href="https://donorbox.org/d2r-app" target="_blank" rel="noreferrer">
          <img className="account__donate__icon" src="https://donorbox.org/images/red_logo.png" alt="donorbox.org" />
          <span className="account__donate__text">Donate</span>
        </a>
      </div>}
    </div>
  );
}
