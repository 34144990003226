import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api';
import util from '../util';
import './Login.css';

export default function Login(props) {

  const account = props.account;
  const { search } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [pass, setPass] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [err, setErr] = useState('');
  const [passReset, setPassReset] = useState(false);

  // If account already set and not within app, redirect to account
  useEffect(() => {
    if (props.account.name && !props.app) {
      util.nav(navigate, '/account', search);
    }
  }, [props.account.name, navigate, search, props.app]);

  // Clear error when name or pass change
  useEffect(() => {
    setErr('');
  }, [name, pass]);

  // Handle 'register' event-- redirect, passing name if available
  function register() {
    const params = new URLSearchParams(search);
    if (name) {
      params.set('id', name);
    }
    let _search = params.toString();
    _search = _search ? '?' + _search : '';
    util.nav(navigate, '/register', _search);
  }

  // Handle 'login' event-- call API, set JWT
  async function login() {
    if (spinner) {
      return;
    }
    if (!name) {
      return setErr('Username or email required');
    }
    if (!pass) {
      return setErr('Password required');
    }
    setSpinner(true);
    util.debounce('login', 1000, async () => {
      const _pass = pass;
      setPass('');
      const res = await api.login(name.trim(), _pass);
      setSpinner(false);
      if (res.data.err) {
        return setErr(res.data.err || '');
      }
      if (!util.initAccount(props.setAccount, res.data)) {
        return setErr('Error initializing account');
      }
      if (!props.app) {
        util.nav(navigate, '/account', search);
      }
    });
  }

  // Handle 'reset' event-- call API, send email
  function reset() {
    if (spinner) {
      return;
    }
    if (!name) {
      return setErr('Username or email required');
    }
    setSpinner(true);
    util.debounce('reset', 1000, async () => {
      const res = await api.resetPassword(name.trim());
      setSpinner(false);
      setErr(res.data.err || '');
      if (!res.data.err) {
        setPassReset(true);
      }
    });
  }

  // Check for 'enter' on key down-- submit login form
  function keyDown(e) {
    if (e?.keyCode === 13) {
      login();
    }
  }

  return (
    <div className="login">
      {account.initialized && !account.name && !passReset && <div className="login__form clearfix">
        <Form.Control onKeyDown={keyDown} value={name} onInput={(e) => setName(e.target.value)} placeholder="Username or email" />
        <Form.Control onKeyDown={keyDown} value={pass} onInput={(e) => setPass(e.target.value)} placeholder="Password" type="password" />
        <button className="login__register" onClick={register}>Register</button>
        <button className="login__submit text-success" onClick={login}>Login</button>
        {spinner && <Spinner className="login__spinner" animation="border" size="sm" />}
        {err && <div className="login__error text-danger">{err}</div>}
        {err && !/API error/.test(err) && <button className="login__reset" onClick={reset}>Reset Password</button>}
      </div>}
      {passReset && <div className="login__reset__message">
        <span className="text-success">If an account matched "{name.trim()}" then a password reset email was sent</span>
      </div>}
    </div>
  );
}
