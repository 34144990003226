const util = {

  getOrigin: () => window.location.origin,
  getApiUrl: () => util.getOrigin() + '/api/',

  nav: (navigate, path, search = '') => {
    navigate(`${path}${search}`);
  },

  timeouts: {},
  debounce: async (key, ms, fn) => {
    clearTimeout(util.timeouts[key]);
    util.timeouts[key] = setTimeout(fn, ms);
  },

  getJWT: function() {
    return localStorage.getItem('jwt');
  },
  setJWT: function(value) {
    return localStorage.setItem('jwt', value);
  },
  removeJWT: function() {
    return localStorage.removeItem('jwt');
  },

  // Initialize account object using provided, or existing, JWT
  initAccount: (setAccount, jwt = util.getJWT()) => {
    const account = util.parseAccountJWT(jwt);
    setAccount({ ...account, initialized: true });
    return account.name;
  },

  // Parse account JWT-- pulling from local storage if not provided, and updating local storage if necessary
  parseAccountJWT: function(jwt) {
    let account = {};
    if (typeof jwt === 'string' && jwt !== 'init') {
      try {
        account = JSON.parse(atob((jwt.split('.')[1] || '').replace(/-/g, '+').replace(/_/g, '/')));
        util.setJWT(jwt);
      }
      catch (e) {
        console.log('Error parsing JWT', e);
      }
    }
    return account;
  }
};

export default util;
