import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import api from '../api';
import d2rLogoSrc from '../assets/d2rApp512.png';
import egArmorySrc from '../assets/egArmory.jpg';
import egConfigSrc from '../assets/egConfig.jpg';
import egFilterSrc from '../assets/egFilter.jpg';
import egGarrisonSrc from '../assets/egGarrison.jpg';
import egLadderSrc from '../assets/egLadder.jpg';
import './Home.css';

export default function Home(props) {

  const [info, setInfo] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [err, setErr] = useState('');

  useEffect(() => {
    if (!info.version) {
      (async () => {
        setSpinner(true);
        const res = await api.getInfo();
        setSpinner(false);
        setErr(res.data.err || '');
        if (!res.data.err) {
          setInfo({ ...res.data });
        }
      })();
    }
  }, [info]);

  const hrefAttrs = { target: '_blank', rel: 'noreferrer' };
  const Download = () => (<div className="home__download">
    {spinner && <Spinner animation="border" size="sm" />}
    {err && <div className="text-danger">{err}</div>}
    {info.version && <div>
      <p>
        <a href={`/dist/d2r.app-v${info.version}.exe`} className="home__download__href">Download d2r.app v{info.version}</a><br />
        {info.size} MB
      </p>
    </div>}
  </div>);

  return (
    <div className="home">
      <img src={d2rLogoSrc} className="home__logo" alt="d2r.app logo" />
      <h1 className="home__header">
        Enhance your Diablo II Resurrected single player experience...
      </h1>
      Create loot filters<br />
      Track grail progress<br />
      Backup heroes<br />
      Enroll in public ladders<br />
      Create private ladders<br />
      <span className="home__new">NEW</span>
      &nbsp;Twitch integration via <a href="https://dashboard.twitch.tv/extensions/4vibccsxy6s7r5sk96i5kfju1h27v0-1.0.2" {...hrefAttrs}>D2R Armory</a>
      <Download />
      <h2 className="home__eg-title">Create Loot Filters, Play <i>Your</i> Way</h2>
      <img className="d-block home__eg-img" src={egFilterSrc} alt="d2r.app loot filter" />
      <h2 className="home__eg-title">Browse Heroes, Browse Stashes, Track Grail Progress</h2>
      <img className="d-block home__eg-img" src={egGarrisonSrc} alt="d2r.app garrison" />
      <h2 className="home__eg-title">Enlist Heroes in Ladders, Share Progress with Friends</h2>
      <img className="d-block home__eg-img" src={egLadderSrc} alt="d2r.app garrison" />
      <h2 className="home__eg-title">Engage Twitch Viewers using <a href="https://dashboard.twitch.tv/extensions/4vibccsxy6s7r5sk96i5kfju1h27v0-1.0.2" {...hrefAttrs}>D2R Armory Panel Extension</a></h2>
      <img className="d-block home__eg-img" src={egArmorySrc} alt="d2r.app armory" />
      <h2 className="home__eg-title">Easy Configuration</h2>
      <img className="d-block home__eg-img" src={egConfigSrc} alt="d2r.app config" />
      <Download />
      <div className="home__footer">
        Made possible by...<br />
        <a href="https://github.com/dschu012/d2s" {...hrefAttrs}>d2s</a>,&nbsp;
        <a href="https://github.com/dschu012/d2s-ui" {...hrefAttrs}>d2s-ui</a>,&nbsp;
        <a href="https://github.com/d2r-app/d2s-ui-react" {...hrefAttrs}>d2s-ui-react</a>
      </div>
    </div>
  );
}
